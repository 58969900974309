<template>
<div class="content">
    <div class="row">

        <div class="col-md-12">
            <vuestic-widget :headerText="$t('view.staff.title')">
                <div class="row">
                    <div class="col-md-3">
                        <button type="button" v-on:click="backPage" class="btn btn-primary btn-sm">Back</button>
                    </div>
                    <div class="col-md-3 offset-md-6">
                        <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
                    </div>

                </div>

            </vuestic-widget>
        </div>
        <div class="col-md-12">
            <vuestic-widget :headerText="header">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                            <div class="input-group">
                                <input id="name" name="name" v-model="name" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="name">{{'view.staff.fields.name' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessEmailValid}">
                            <div class="input-group">
                                <input id="email" name="email" v-model="email" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="email">{{'view.staff.fields.email' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('email')" class="help text-danger">
                                                        {{ errors.first('email') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 offset-md-6">
                        <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                            <div class="input-group">
                                <vuestic-switch v-model="active">
                                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                </vuestic-switch>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                <small v-show="errors.has('active')" class="help text-danger">
                                                        {{ errors.first('active') }}
                                                    </small>
                            </div>
                        </div>

                        <!-- <vuestic-simple-select :label="'view.staff.fields.typestaff' | translate" v-model="idtypestaff" option-key="name" v-bind:options="typestaffoptions">
                        </vuestic-simple-select> -->

                    </div>
                </div>
                <div class="row" v-show="statusEvent === 1">
                    <button type="button" v-on:click="saveStaff" class="btn btn-primary btn-sm">Add New Staff</button>
                </div>

            </vuestic-widget>

        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import TableStaff from '../tables/TableStaff/TableStaff.vue'

import {
  SpringSpinner
} from 'epic-spinners'
import {
  mapState,
  mapActions
} from 'vuex'
export default {
  name: 'new-staff',
  components: {
    SpringSpinner,
    TableStaff
  },
  mounted () {
    // checkbox and radios
    this.loadTypestaffsActive()
      .then(data => {
        this.typestaffoptions = this.typestaffs
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })
  },
  computed: {
    ...mapState({
      typestaffs: state => state.typestaff.typestaffs,
      idevent: state => state.event.event.id,
      statusEvent: state => state.event.event.status,
    }),
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessEmailValid () {
      let isValid = false
      if (this.formFields.email) {
        isValid =
              this.formFields.email.validated && this.formFields.email.valid
      }
      return isValid
    }
  },
  data () {
    return {
      header: 'Nuevo Staff',
      typestaffoptions: null,
      name: '',
      code: '',
      email: '',
      active: 1,
      idtypestaff: '',

      eventoptions: null,

    }
  },
  methods: {
    ...mapActions([
      'loadTypestaffsActive',
      'storeStaff',
      'addToastMessage'
    ]),
    backPage: function () {
      this.$router.replace('/staff')
    },
    saveStaff () {
      this.$validator.validate().then(result => {
        if (result) {
          this.storeStaff({
            name: this.name,
            code: this.code,
            email: this.email,
            id_event: this.idevent,
            active: this.active,
            id_type_staff: this.idtypestaff.id
          })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() => this.$router.replace('/staff'), 2000)
            })
            .catch((data) => {
              this.error = data.message
              this.addToastMessage({
                text: data.message,
                type: 'warning'
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.color-icon-label-table {
    td:first-child {
        width: 1rem;
    }
}
</style>
